import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    addCartLineMutation,
    cartLinesRemoveMutation,
    createCartMutation,
    fetchCartQuery,
    generateCreateCartVariables,
    updateCartLineMutation,
} from '../queries/cartQueries';
import { eventsWithVariantsAndMerchandisesQuery } from '../queries/eventQueries';
import type { CartItemsProductIdsByType } from '../../components/cart/utils';
import type {
    CartLinesRemoveMutationResponse,
    RemoveCartLinesVariables,
    ShopifyAddCartLinesDataResponse,
    ShopifyCreateCartDataResponse,
    ShopifyFetchCartDataResponse,
    ShopifyUpdateCartLinesDataResponse,
} from '../../types/cart';
import type {
    AddCartLinesInput,
    CreateCartQueryArgs as CreateCartQueryArguments,
    FetchCartLinesInput,
    UpdateCartLinesInput,
} from '../queries/cartQueries';
import type { EventsAndMerchandisesResponse } from '../queries/eventQueries';
import { transformToOldEvent } from '@/utils/data-fetching/fetch/utils';
import { ShopifyGenericResponse } from '@/types/Shopify';
import fetchShopify from '@/utils/fetchShopify';
import { ContentfulGenericResponse } from '@/types/contentful/api';
import fetchContentful from '@/utils/fetchContentful';

export enum CartActionTypes {
    createCart = 'cart/create',
    addCartLines = 'cart/add-lines',
    updateCartLines = 'cart/update-lines',
    fetchCart = 'cart/fetch',
    fetchCartByExternalId = 'cart/fetch-by-external-id',
    fetchCartEventsAndMerchandisesContentfulData = 'cart/fetch-cart-event-and-merchandises-contentful-data',
    removeCartLine = 'cart/remove-line',
    emptyCart = 'cart/empty-cart',
    resetNotificationStatus = 'cart/reset-notification-status',
}

export const emptyCart = () => ({
    type: CartActionTypes.emptyCart,
});

export const resetNotificationStatus = () => ({
    type: CartActionTypes.resetNotificationStatus,
});

export const createCartAction = createAsyncThunk(
    CartActionTypes.createCart,
    async (
        {
            createCartVariables,
        }: {
            createCartVariables: CreateCartQueryArguments;
        },
        { rejectWithValue },
    ) => {
        const {
            data,
            errors,
        }: ShopifyGenericResponse<ShopifyCreateCartDataResponse> =
            await fetchShopify({
                query: createCartMutation,
                queryVariables:
                    generateCreateCartVariables(createCartVariables),
            });
        if (errors || !data.cartCreate.cart) {
            return rejectWithValue(errors?.[0].message);
        }

        return {
            cart: data.cartCreate.cart,
            countryCode: createCartVariables.countryCode,
        };
    },
);

export const addCartLinesAction = createAsyncThunk(
    CartActionTypes.addCartLines,
    async (
        { shopifyVariantId, ...addCartLinesArguments }: AddCartLinesInput,
        { rejectWithValue },
    ) => {
        const {
            data,
            errors,
        }: ShopifyGenericResponse<ShopifyAddCartLinesDataResponse> =
            await fetchShopify({
                query: addCartLineMutation,
                queryVariables: addCartLinesArguments,
            });

        if (errors || !data.cartLinesAdd.cart) {
            return rejectWithValue(errors?.[0].message);
        }

        return {
            cart: data.cartLinesAdd.cart,
            countryCode: addCartLinesArguments.countryCode,
        };
    },
);

export const updateCartLinesAction = createAsyncThunk(
    CartActionTypes.updateCartLines,
    async (
        { shopifyVariantId, ...updateCartLinesArguments }: UpdateCartLinesInput,
        { rejectWithValue },
    ) => {
        const {
            data,
            errors,
        }: ShopifyGenericResponse<ShopifyUpdateCartLinesDataResponse> =
            await fetchShopify({
                query: updateCartLineMutation,
                queryVariables: updateCartLinesArguments,
            });

        if (errors || !data.cartLinesUpdate.cart) {
            return rejectWithValue(errors?.[0].message);
        }

        return {
            cart: data.cartLinesUpdate.cart,
            countryCode: updateCartLinesArguments.countryCode,
        };
    },
);

export const fetchCartAction = createAsyncThunk(
    CartActionTypes.fetchCart,
    async (
        { cartId, countryCode }: FetchCartLinesInput,
        { rejectWithValue },
    ) => {
        const {
            data,
            errors,
        }: ShopifyGenericResponse<ShopifyFetchCartDataResponse> =
            await fetchShopify({
                query: fetchCartQuery,
                queryVariables: { cartId, countryCode },
            });
        if (errors || !data.cart) {
            return rejectWithValue(errors?.[0].message);
        }
        return { cart: data.cart, countryCode };
    },
);
// this action dispatched when the cart desired to be fetched through an external Id that has passed to the search parameters
export const fetchCartByExternalIdAction = createAsyncThunk(
    CartActionTypes.fetchCartByExternalId,
    async (
        { cartId, countryCode }: FetchCartLinesInput,
        { rejectWithValue },
    ) => {
        const {
            data,
            errors,
        }: ShopifyGenericResponse<ShopifyFetchCartDataResponse> =
            await fetchShopify({
                query: fetchCartQuery,
                queryVariables: { cartId, countryCode },
            });

        if (errors || !data.cart) {
            return rejectWithValue(errors?.[0].message);
        }
        return { cart: data.cart, countryCode };
    },
);

export const fetchCartEventsAndMerchandisesContentfulDataAction =
    createAsyncThunk(
        CartActionTypes.fetchCartEventsAndMerchandisesContentfulData,
        async (
            {
                productIds,
            }: {
                productIds: CartItemsProductIdsByType;
            },
            { rejectWithValue },
        ) => {
            const {
                data,
                errors,
            }: ContentfulGenericResponse<EventsAndMerchandisesResponse> =
                await fetchContentful({
                    query: eventsWithVariantsAndMerchandisesQuery,
                    queryVariables: {
                        eventIds: productIds.events,
                        addonIds: productIds.addons,
                    },
                });

            const formattedData = {
                ...data,
                eventCollection: {
                    items: data?.simpleEventCollection?.items.map((item) => {
                        const transformedEvent = transformToOldEvent(item);

                        return {
                            ...transformedEvent,
                            poster: transformedEvent.poster.asset,
                        };
                    }),
                },
            };

            if (
                errors ||
                !formattedData?.eventCollection ||
                !formattedData.merchandiseCollection
            ) {
                return rejectWithValue(errors?.[0]?.message);
            }

            return formattedData;
        },
    );

export const removeCartLineItemsAction = createAsyncThunk(
    CartActionTypes.removeCartLine,
    async (
        {
            queryVariables,
        }: {
            queryVariables: RemoveCartLinesVariables;
        },
        { rejectWithValue },
    ) => {
        const {
            data,
            errors,
        }: ShopifyGenericResponse<CartLinesRemoveMutationResponse> =
            await fetchShopify({
                query: cartLinesRemoveMutation,
                queryVariables,
            });

        if (errors || !data.cartLinesRemove) {
            return rejectWithValue(errors?.[0].message);
        }

        return {
            cart: data.cartLinesRemove.cart,
            countryCode: queryVariables.countryCode,
        };
    },
);
