import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { NotificationType } from '../enums/Notification';
import { profileMessages } from '../messages/profile';
import { customerAccessTokenCreateQuery } from '../queries/authQueries';
import { getCustomerErrorMessage } from '../errors/utils';
import { genericAPIMessages } from '../messages/genericAPI';

import fetchShopify from '@/utils/fetchShopify';
import { ShopifyCustomerResponse } from '@/types/Shopify';
import { NotificationObject } from '@/types/Notification';
import { buildNotification } from '@/utils/notification';

export type VerifyPasswordStatus =
    | 'VERIFY_PASSWORD_PENDING'
    | 'VERIFY_PASSWORD_SUCCESSFUL'
    | 'VERIFY_PASSWORD_FAILURE';

export interface VerifyPasswordState {
    notification?: NotificationObject;
    status?: VerifyPasswordStatus;
}

export enum VerifyPasswordActionTypes {
    verifyPassword = 'profile/verifyPassword',
}

export const verifyPasswordSliceInitialState: VerifyPasswordState = {};

export const verifyPasswordAction = createAsyncThunk(
    VerifyPasswordActionTypes.verifyPassword,
    async (
        payload: {
            email: string;
            password: string;
        },
        { rejectWithValue },
    ) => {
        const { email, password } = payload;

        const { variables, mutation } = customerAccessTokenCreateQuery(
            email,
            password,
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { errors, data }: ShopifyCustomerResponse = await fetchShopify({
            query: mutation,
            queryVariables: variables,
        });

        if (
            errors?.length ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            data?.customerAccessTokenCreate?.customerUserErrors?.length > 0
        ) {
            const errorMessage =
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                data?.customerAccessTokenCreate?.customerUserErrors?.[0]
                    ?.code === 'UNIDENTIFIED_CUSTOMER'
                    ? profileMessages.error_verifying_password
                    : getCustomerErrorMessage({
                          defaultErrorMessage:
                              genericAPIMessages.error_performing_request,
                          errors,
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          errorCode:
                              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                              data?.customerAccessTokenCreate
                                  ?.customerUserErrors?.[0].code,
                      });
            return rejectWithValue(errorMessage);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data;
    },
);

export const verifyPasswordSlice = createSlice({
    name: 'verifyPassword',
    initialState: verifyPasswordSliceInitialState,
    reducers: {
        resetState: (state) => {
            state.notification = undefined;
            state.status = undefined;
        },
        updateNotification: (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.notification = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(verifyPasswordAction.fulfilled, (state) => {
                state.status = 'VERIFY_PASSWORD_SUCCESSFUL';
            })
            .addCase(verifyPasswordAction.rejected, (state, { payload }) => {
                state.status = 'VERIFY_PASSWORD_FAILURE';
                state.notification = buildNotification(
                    String(payload),
                    NotificationType.error,
                );
            })
            .addCase(verifyPasswordAction.pending, (state) => {
                state.status = 'VERIFY_PASSWORD_PENDING';
                state.notification = undefined;
            });
    },
});

export const verifyPasswordActions = verifyPasswordSlice.actions;
