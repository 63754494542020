import template from 'lodash.template';

import { customerMessages } from '../messages/customer';
import { ShopifyCustomerErrorCode, ShopifyResponse } from '@/types/Shopify';

interface GetCustomerErrorMessage {
    defaultErrorMessage: string;
    errors?: ShopifyResponse['errors'];
    errorCode?: ShopifyCustomerErrorCode;
    field?: string; // affected field, for example 'email'
}

export const getCustomerErrorMessage = ({
    defaultErrorMessage,
    errors,
    errorCode,
    field,
}: GetCustomerErrorMessage): string => {
    // TODO improve error messages to specify which field is responsible of the error
    switch (errorCode) {
        case 'ALREADY_ENABLED': {
            return customerMessages.error_already_enabled;
        }
        case 'BAD_DOMAIN': {
            return customerMessages.error_email_bad_domain;
        }
        case 'BLANK': {
            return customerMessages.error_blank;
        }
        case 'CONTAINS_HTML_TAGS': {
            return customerMessages.error_contains_html_tag;
        }
        case 'CONTAINS_URL': {
            return customerMessages.error_contains_url;
        }
        case 'CUSTOMER_DISABLED': {
            return customerMessages.error_customer_disabled;
        }
        case 'TOKEN_INVALID':
        case 'INVALID_MULTIPASS_REQUEST': {
            return customerMessages.error_invalid_session;
        }
        case 'NOT_FOUND': {
            return customerMessages.error_not_found;
        }
        case 'PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE': {
            return customerMessages.error_password_starts_or_ends_with_whitespace;
        }
        case 'TAKEN': {
            // in theory 'field' should always be defined when the errorCode is TAKEN
            return template(customerMessages.error_taken)({
                input: field ?? 'unknown field',
            });
        }
        case 'TOO_LONG': {
            return customerMessages.error_too_long;
        }
        case 'UNIDENTIFIED_CUSTOMER': {
            return customerMessages.error_unidentified_customer;
        }
        case 'TOO_SHORT': {
            return customerMessages.error_password_too_short;
        }
        case 'INVALID': {
            return customerMessages.error_invalid_reset_password_url;
        }
        default: {
            return errors?.[0].message || defaultErrorMessage;
        }
    }
};
