import type { Document } from '@contentful/rich-text-types';

export const CONTENTFUL_VERSION = '2';

export interface ContentfulAsset {
    title?: string;
    description?: string;
    // If the asset is in draft state the file won't be available.
    url: string;
    width: number;
    height: number;
    file?: {
        url: string;
        details?: {
            size: number;
            image: {
                width: number;
                height: number;
            };
        };
        fileName?: string;
        contentType?: string;
    };
}

export interface ContentfulImage {
    __type?: 'image';
    title?: string;
    url?: string;
    alt: string;
    asset?: ContentfulAsset;
}

export type AvailablePlatformsToMessage =
    | 'web'
    | 'ios-mobile'
    | 'android-mobile'
    | 'ios-tv'
    | 'android-tv'
    | 'fire-tv'
    | 'lg-tv';

export interface PlatformMessage {
    description: string;
    updatedAt: string;
}
export type ContentfulSEO = {
    title: string;
    description: {
        description: string;
    };
    image?: ContentfulImage;
};

export interface ContentfulHero {
    __type?: 'genericHero';
    title: string;
    subtitle: string;
    button?: ContentfulButton;
    background: ContentfulImage;
    video: ContentfulGenericVariantStream;
    layout: 'left' | 'right' | 'center';
    meta?: ContentfulMeta;
}

export interface ContentfulGenericVariantStream {
    __type?: 'streamBlock';
    id: string;
    title: string;
    hlsUrl: string;
    dashUrl?: string;
    dashCastUrl: string;
    thumbnailImage: ContentfulImage;
}

export interface ContentfulMeta {
    date: string;
    duration: string;
    icon: ContentfulImage;
}

export type ContentfulFlexiblePageHero = ContentfulHero | ContentfulEventHero;

export type ContentfulFlexiblePage = {
    slug: string;
    seo?: ContentfulSEO;
    hero?: ContentfulFlexiblePageHero;
    blocks: ContentfulFlexiblePageBlock[];
};

export type ContentfulPreviewPage = {
    slug: string;
    seo?: ContentfulSEO;
    blocks?: ContentfulFlexiblePageBlock[];
    streams: ContentfulStream[];
    accessCode: string;
    streamSecurity: ContentfulStreamSecurity;
};

export interface ContentfulPrice {
    __type?: 'price';
    title: string;
    EUR: number;
    eur?: number;
    USD: number;
    AUD: number;
    GBP: number;
}
export interface ContentfulGenre {
    __type?: 'genre';
    title: string;
    displayLabel: string;
}

export interface ContentfulTile {
    __type?: 'Tile';
    id: string;
    title: string;
    shortTitle?: string;
    label: string;
    landscapeImage: ContentfulImage;
    slug: string;
    sublabel?: string;
    logo?: ContentfulImage;
}
export interface ContentfulIcon {
    __type?: 'icon';
    type: string;
}
export interface ContentfulEventOption {
    __type?: 'eventOptions';
    position: number;
    value: string;
}

export type ContentfulEventVariant = {
    __type?: 'eventVariant';

    title: string;

    sku: string;

    shopifyProductVariantId?: string;

    doorsOpen: string;

    eventStart: string;

    saleEnd?: string;

    eventEnd: string;

    dashUrl?: string;

    hlsUrl?: string;
    mp4Url?: string;
    dashCastUrl?: string;
    compareAtPrice?: ContentfulPrice;
    options?: ContentfulEventOption[];
    icons?: ContentfulIcon[];
    stream?: ContentfulStream;
    verticalIcons?: ContentfulIcon[];
    price?: ContentfulPrice;
};
export type ContentfulEventTag =
    | 'future-events'
    | 'past-events'
    | 'currently-vod';

export interface ContentfulSocialLinks {
    __type?: 'socialLinks';
    facebookLink?: string;
    twitterLink?: string;
    instagramLink?: string;
}
export interface ContentfulEventBlacklistedCountry {
    countryCode: string;
    partnerName?: string;
    partnerLink?: string;
}
export interface ContentfulReduxAction {
    __type?: 'reduxAction';
    type: string;
    payload: string;
}
export interface ContentfulLink {
    __type?: 'link';
    text: string;
    href: string;
    icon?: ContentfulIcon;
    reduxAction?: ContentfulReduxAction;
    shouldFollow?: boolean;
    shouldOpenInNewTab?: boolean;
}
export type Currency = 'USD' | 'EUR' | 'GBP' | 'AUD';
export const DEFAULT_CURRENCY: Currency = 'EUR';
export const DEFAULT_CURRENCY_SIGN = '€';
export const DEFAULT_COUNTRY_CODE = 'NL';
export type EventVariantOption = EventOption & {
    icons?: ContentfulIcon[];
    price?: ContentfulPrice;
    compareAtPrice?: ContentfulPrice;
};
export interface EventOption {
    __type?: 'eventOptions';
    position: number;
    value: string;
}
export type ContentfulContentBlockType =
    | 'Generic'
    | 'Artist'
    | 'EventDescription';

export type ContentfulDocument = Document;
export type Event = ContentfulEvent;
export interface ContentfulGraphqlRichDescription {
    raw: string;
}
export type ContentfulButtonType =
    | 'solid-primary'
    | 'solid-secondary'
    | 'outline-primary'
    | 'outline-secondary'
    | 'button-styled-in-component';

export type ContentfulButton = {
    __type?: 'button';
    text: string;
    type: ContentfulButtonType;
    ariaLabel: string;
    href: string;
    shouldOpenLinkInNewTab: boolean;
    isBuyButton?: boolean;
};

export interface ImageAsset {
    asset?: {
        description?: string;
        title: string;
        url: string;
        width: number;
        height: number;
    };
}

export interface ContentfulStream {
    __type?: 'stream';
    id?: string;
    title: string;
    hlsUrl?: string;
    dashUrl?: string;
    dashCastUrl?: string;
    dashAppUrl?: string;
    duration?: number;
    thumbnailImage?: ImageAsset;
}

export interface AuthXML {
    authXML: string;
}

export interface ContentfulStreamSecurity {
    id?: string;
    __type?: 'streamSecurity';
    authXML?: AuthXML;
    certUrl?: string;
    poster: ContentfulImage;
    chromecastReceiverAppId?: string;
}

export type ContentfulContentBlock = {
    id?: string;
    __type?: 'contentBlock';
    title: string;
    image: ContentfulImage;
    isImageOnTheLeft: boolean;
    links?: ContentfulLink[];
    shouldShowSocialButtons: boolean;
    type: ContentfulContentBlockType;
    event?: ContentfulEvent;
    subtitle?: string;
    description?:
        | ContentfulGraphqlRichDescription
        | ContentfulDocument
        | string;
    imagePosition?: 'left' | 'center' | 'right';
    socialMediaIconsTitle?: string;
    showAppStoreBanners: boolean;
    button?: ContentfulButton;
};
export interface ContentfulSellingPoint {
    __type?: 'eventFeature';
    title: string;
    description: ContentfulGraphqlRichDescription | ContentfulDocument | string;
    icon: ContentfulIcon;
}
export interface ContentfulGallery {
    id?: string;
    __type?: 'galleryBlock';
    images: ContentfulImage[];
    preserveAspectRatio: boolean;
}

export type ContentfulSellingPoints = {
    id?: string;
    __type?: 'sellingPointsSection';
    title: string;
    sellingPoints: ContentfulSellingPoint[];
    showTitle: boolean;
};
export type ContentfulVideo = {
    __type?: 'videoBlock';
    id?: string;
    youtubeLink?: string;
    selfHostedVideo?: ContentfulStream;
};
export interface ContentfulTestimonialBlock {
    id?: string;
    __type?: 'testimonialBlock';
    title: string;
    testimonials: ContentfulTestimonial[];
}

export interface ContentfulTestimonial {
    id?: string;
    authorName: string;
    rating: number;
    date?: string;
    content: {
        content: string;
    };
    authorPicture?: ContentfulImage;
    source?: ContentfulIcon;
}
export interface ContentfulSpotifyPlaylist {
    id: string;
    __type: 'spotifyPlaylist';
    callToActionButton: ContentfulButton;
    embeddedPlaylist: {
        embeddedPlaylist: string;
    };
    title?: string;
    text?: ContentfulGraphqlRichDescription | ContentfulDocument | string;
}
export interface ContentfulHighlight {
    __type?: 'highlight';
    id?: string;
    title: string;
    text: ContentfulGraphqlRichDescription | ContentfulDocument | string;
    icon?: ContentfulIcon;
}
export interface ContentfulHighlightsBlock {
    __type?: 'highlightsBlock';
    id?: string;
    // title: string; // for internal use only
    highlights: ContentfulHighlight[];
}

export type ContentfulFlexiblePageBlock =
    | ContentfulContentBlock
    | ContentfulGallery
    | ContentfulEventCarousel
    | ContentfulSellingPoints
    | ContentfulVideo
    | ContentfulTestimonialBlock
    | ContentfulSpotifyPlaylist
    | ContentfulHighlightsBlock;

export type ContentfulEventBlock =
    | ContentfulContentBlock
    | ContentfulSellingPoints
    | ContentfulGallery
    | ContentfulVideo
    | ContentfulSpotifyPlaylist
    | ContentfulEventCarousel
    | ContentfulHighlightsBlock;

export type ContentfulEventType = 'vod' | 'spo';
export type ContentfulEvent = {
    __type?: 'event';
    title: string;
    slug: string;
    shortDescription?: string;
    shopifyProductId: string;
    poster: ContentfulImage;
    portraitPoster?: ContentfulImage;
    eventInfoLogo?: ContentfulImage;
    tags?: ContentfulEventTag[];
    socialLinks?: ContentfulSocialLinks;
    createdAt?: string;
    blacklistedCountries?: ContentfulEventBlacklistedCountry[];
    type: ContentfulEventType;
    id?: string;
    variants: ContentfulEventVariant[];
    genres?: ContentfulGenre[];
    preferredVariant?: ContentfulEventVariant;
    seo?: ContentfulSEO;
    carouselLogo?: ContentfulImage;
    trailer?: ContentfulStream;
    blocks?: ContentfulEventBlock[];
    duration?: number;
    shortTitle?: string;
    relatedShows?: ContentfulEventCarousel;
    eventStart: string;
};

export type ContentfulEventCarouselContent = ContentfulTile | ContentfulEvent;
export type RequestPlatform = 'web' | 'mobile' | 'tv';
export type EventPrice = ContentfulPrice;
export type ContentfulEventCarousel = {
    id?: string;
    __type?: 'eventCarouselBlock';
    title: string;
    landscape: boolean;
    genres?: ContentfulGenre[];
    content?: ContentfulEventCarouselContent[];
    sortingOrder?: 'descending' | 'ascending';
    platforms?: RequestPlatform[];
    eventTag?: ContentfulEventTag;
    showDates: boolean;
    events: ContentfulEvent[];
    size: string;
    slug?: string;
    category?: string;
};
export type ContentfulEventHeroTheme = 'default' | 'dark';

export type ContentfulEventHero = {
    __type?: 'hero';
    nickname: string;
    background: ContentfulImage;
    eventInfoLogo: ContentfulImage;
    theme: ContentfulEventHeroTheme;
    event: ContentfulEvent;
    description: {
        raw: string;
        json: any;
    };
};
export type EventStatus = 'FUTURE' | 'PAST' | 'LIVE';

// Re-export commonly contentful event-types with a shorter
// notation, without the `Contentful` suffix.
// export type Event = ContentfulEvent;
export type EventStream = ContentfulStream;
export type EventVariant = ContentfulEventVariant;
// export type EventMerchandise = ContentfulEventMerchandise;
// export type EventOption = ContentfulEventOption;
export type EventBlock = ContentfulEventBlock;
export type EventTag = ContentfulEventTag;
export type EventType = ContentfulEventType;
// export type EventPrice = ContentfulPrice;
// export type EventVariantOption = EventOption & {
//     icons?: ContentfulIcon[];
//     price?: ContentfulPrice;
//     compareAtPrice?: ContentfulPrice;
// };
