import { Carousel, ContentCollection, SimpleVariant } from '@/types/api';
import { ContentfulFlexiblePageBlock } from '@/types/contentful';
import { normalizeContentfulDeliveryResponse } from '@/utils/contentful/normalizers';
import { getEnvironmentVariableServerSide } from '@/utils/server-functions/getEnvServerSide';
export const transformToOldEvent = (eventLight: any) => {
    const eventLightFormatted = normalizeContentfulDeliveryResponse(eventLight);
    const addTagsToEvent = ({
        startTime,
        showEnd,
    }: {
        startTime: string;
        showEnd: string;
    }) => {
        const currentDate = new Date();

        if (new Date(startTime) > currentDate) {
            return 'future-events';
        }
        if (new Date(showEnd) < currentDate) {
            return 'past-event';
        }

        return 'current-vod';
    };

    const fixedBlocks = [
        { __type: 'contentBlock', ...eventLightFormatted.eventDescription },
        { __type: 'contentBlock', ...eventLightFormatted.artistBioBlock },
        { __type: 'videoBlock', selfHostedVideo: eventLightFormatted.trailer },
        {
            __type: 'sellingPointsSection',
            sellingPoints:
                eventLightFormatted.sellingPoints?.sellingPointsCollection,
        },
        {
            __type: 'galleryBlock',
            images: eventLightFormatted.imageGallery?.imagesCollection,
        },
        { __type: 'spotifyPlaylist', ...eventLightFormatted.spotifyPlaylist },
        {
            __type: 'highlightsBlock',
            ...eventLightFormatted.highlights,
            highlights: eventLightFormatted.highlights?.highlightsCollection,
        },
        { __type: 'contentBlock', ...eventLightFormatted.treePlantedBlock },
        { __type: 'contentBlock', ...eventLightFormatted.buyShowNowBlock },
    ];

    const formatBlacklist = (
        blacklists: { '0': string; '1': string }[],
    ): { countryCode: string }[] =>
        blacklists?.map((country) => ({
            countryCode: `${country['0']}${country['1']}`,
        }));

    const formatVariant = (variants: SimpleVariant[]) => {
        const variantsOld = variants?.map((variant: SimpleVariant) => {
            return {
                id: variant.id,
                sku: variant.sku,
                options: [
                    {
                        position: 1,
                        value: variant.quality.displayLabel,
                    },
                ],
                icons:
                    variant.quality.horizontalQualityIconsCollection?.items ||
                    variant.quality.horizontalQualityIconsCollection,
                verticalIcons:
                    variant.quality.verticalQualityIconsCollection?.items ||
                    variant.quality.verticalQualityIconsCollection,
                title: variant.title,
                doorsOpen: eventLightFormatted.doorsOpenTime,
                eventStart: eventLightFormatted.showStartTime,
                saleEnd: eventLightFormatted.saleEndTime,
                eventEnd: eventLightFormatted.showEndTime,
                price: variant.price,
                compareAtPrice: variant.compareAtPrice,
                shopifyProductVariantId: variant.shopifyProductVariantId,
                stream: variant.stream,
            };
        });

        return variantsOld;
    };

    const eventOldFormat = {
        id: eventLightFormatted.id,
        title: eventLightFormatted.title,
        shortTitle: eventLightFormatted.shortTitle,
        slug: eventLightFormatted.slug,
        seo: eventLightFormatted.seo,
        shortDescription: eventLightFormatted.shortDescription,
        poster: {
            asset: eventLightFormatted.landscapeImage,
        },
        eventInfoLogo: {
            asset: eventLightFormatted.showLogo,
        },
        portraitPoster: {
            asset: eventLightFormatted.portraitImage,
        },
        carouselLogo: {
            asset: eventLightFormatted.artistLogo,
        },
        trailer: eventLightFormatted.trailer,
        tags: addTagsToEvent({
            startTime: eventLightFormatted.startTime,
            showEnd: eventLightFormatted.showEnd,
        }),
        vendors: eventLightFormatted.producer,
        venue: eventLightFormatted.venue,
        genres: eventLightFormatted.genres,
        variants: eventLightFormatted.variantComplex?.length
            ? eventLightFormatted.variantComplex
            : formatVariant(eventLightFormatted.variants),
        preferredVariant: formatVariant(eventLightFormatted.variants)?.[0],
        socialLinks: eventLightFormatted.socialLinks,
        merchandises: eventLightFormatted.merchandises,
        blocks: fixedBlocks,
        blacklistedCountries: formatBlacklist(eventLight.blacklist?.countries),
        type: eventLight.type,
        shopifyProductId: eventLight.shopifyProductId,
        relatedShows: eventLight.relatedShows,
    };

    return eventOldFormat;
};

export const fetchCarouselContent = async (
    data: Carousel[],
    page = 1,
    take = 10,
    category?: string,
    slug?: string,
) => {
    const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
        'ONAIR_ENDPOINT',
    ]);

    const buildUrl = (
        baseEndpoint: string,
        params: {
            platform: string;
            skip: number;
            take: number;
            carouselId: string;
            pageId: string;
            filterId: string;
        },
    ) => {
        const queryParams = new URLSearchParams({
            ...params,
            skip: params.skip.toString(),
            take: params.take.toString(),
        });
        return `${baseEndpoint}?${queryParams.toString()}`;
    };

    const fetchEvents = async ({
        data,
        page,
        take,
        category,
    }: {
        data: Carousel[];
        page: number;
        take: number;
        category?: string;
    }) => {
        const commonParams = {
            platform: 'web',
            skip: (page - 1) * take,
            take,
        };

        const promises = data.map((d) => {
            const params = {
                ...commonParams,
                carouselId: d.id,
                pageId: category || '',
                filterId: slug || '',
            };
            const endpoint =
                category && slug
                    ? `${ONAIR_ENDPOINT}/dynamic-page/events`
                    : `${ONAIR_ENDPOINT}/discover/events`;

            const url = buildUrl(endpoint, params);

            return fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
                cache: 'no-store',
            }).then((response) => response.json());
        });

        return Promise.all(promises);
    };

    let carousels: Array<{
        id: string;
        title: string;
        size: string;
        __type: string;
        landscape: boolean;
        showDates: boolean;
        events: any;
        category?: string;
        slug?: string;
    }> = [];

    try {
        const promiseResponses = await fetchEvents({
            data,
            page,
            take,
            category,
        });

        carousels = promiseResponses.map((collection, index) => {
            const events =
                collection?.data?.contentCollection?.map(
                    (content: ContentCollection) => ({
                        ...content,
                        poster: { asset: content.landscapeImage },
                        portraitPoster: { asset: content.portraitImage },
                        eventInfoLogo: { asset: content.showLogo },
                        carouselLogo: { asset: content.artistLogo },
                    }),
                ) || [];

            return {
                id: data[index].id,
                title: data[index].title,
                size: data[index].size,
                __type: 'eventCarouselBlock',
                landscape: data[index].size === 'small',
                showDates: data[index].showDates,
                category,
                slug,
                events,
            };
        });
    } catch (error) {
        console.error('Error fetching carousel data:', error);
    }

    return carousels;
};

export const fetchDynamicPage = async ({
    preview,
    category,
    item,
    blocks,
}: {
    preview: boolean;
    item: {
        title: string;
        displayTitle: string;
    };
    category: string;
    blocks: ContentfulFlexiblePageBlock[];
}) => {
    try {
        const carouselsData = await fetchCarouselsData(
            preview,
            category,
            item.title,
        );

        const [hero, pageCarousels] = await Promise.all([
            fetchHeroData(carouselsData, preview),
            fetchCarouselContent(
                carouselsData?.discoverCarousels,
                1,
                10,
                category,
                item.title,
            ),
        ]);

        if (!hero && !pageCarousels.length && !blocks.length)
            return { error: 404 };

        //@ts-ignore
        const metaTitle = item.displayTitle || item.displayLabel;
        const order = getOrder(blocks);

        return {
            hero: hero || { ...carouselsData?.hero },
            blocks: [...pageCarousels, ...blocks].sort(
                (a, b) => order?.indexOf(a.id) - order?.indexOf(b.id),
            ),
            seo: generateSEO(category, metaTitle),
        };
    } catch (error) {
        return { error: 404 };
    }
};

const fetchCarouselsData = async (
    preview: boolean,
    category: string,
    filterId: string,
) => {
    const response = await fetch(
        `${
            process.env.ONAIR_ENDPOINT
        }/dynamic-page/carousels?pageId=${category}&platform=web&skip=0&take=100&preview=${
            preview ? 'true' : 'false'
        }&filterId=${filterId}`,
        {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
            cache: 'no-store',
        },
    );

    const { data, error } = await response.json();
    if (error) {
        console.error('Error fetching carousels data:', error);
        return null;
    }
    return data;
};

const getOrder = (blocks: ContentfulFlexiblePageBlock[]) => {
    return blocks?.map((b) => b?.id);
};

const fetchHeroData = async (carouselsData: any, preview: boolean) => {
    const heroId = carouselsData?.hero?.id || carouselsData?.hero?.event.id;
    try {
        const response = await fetch(
            `${process.env.ONAIR_ENDPOINT}/event?eventId=${heroId}&preview=${
                preview ? 'true' : 'false'
            }`,
            {
                method: 'GET',
                headers: { 'content-type': 'application/json' },
                cache: 'no-store',
            },
        );

        const { data } = await response.json();
        const heroEvent = data?.event ? transformToOldEvent(data.event) : null;
        if (!data && !carouselsData.hero) return null;

        return !data
            ? carouselsData.hero
            : {
                  background: heroEvent?.poster,
                  __typename: 'Hero',
                  ...heroEvent,
                  event: heroEvent,
              };
    } catch (error) {
        console.log(error);
    }
};

const generateSEO = (category: string, metaTitle: string) => {
    const isVenue = category === 'venue';
    return {
        title: isVenue
            ? `${metaTitle} – Stream Live Concerts, Shows & Events | On Air`
            : `Stream Live ${metaTitle} Music Concerts & Films | On Air`,
        description: isVenue
            ? `Streaming on demand: live concerts, events and shows from ${metaTitle}. Available now in cinematic quality.`
            : ` Streaming on demand: live ${metaTitle} music concerts, events and shows from your favourite artists and bands in cinematic quality.`,
    };
};

export const fetchFlexiblePageData = async ({
    data,
    preview,
    slug,
}: {
    data: any;
    preview: boolean;
    slug: string;
}) => {
    try {
        const fetchCarousels = fetch(
            `${process.env.ONAIR_ENDPOINT}/discover/carousels?platform=web&skip=0&take=100&preview=${preview ? 'true' : 'false'}`,
            {
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                },
                cache: 'no-store',
            },
        );

        const [carouselsResponse] = await Promise.all([fetchCarousels]);

        const { data: carouselsData } = await carouselsResponse.json();

        const heroEventId = carouselsData?.hero?.event?.id;
        const fetchHeroEvent = heroEventId
            ? fetch(
                  `${process.env.ONAIR_ENDPOINT}/event?eventId=${heroEventId}&preview=${preview ? 'true' : 'false'}`,
                  {
                      method: 'GET',
                      headers: {
                          'content-type': 'application/json',
                      },
                      cache: 'no-store',
                  },
              )
            : null;

        const heroResponse = fetchHeroEvent ? await fetchHeroEvent : null;
        const heroData = heroResponse ? await heroResponse.json() : null;

        let heroEventFormatted = {};
        if (heroData) {
            const heroEvent = transformToOldEvent(heroData?.data?.event);
            heroEventFormatted = {
                background: heroEvent.poster,
                __typename: 'Hero',
                ...heroEvent,
                event: heroEvent,
            };
        }

        const normalizedResponse =
            normalizeContentfulDeliveryResponse(data).flexiblePage?.[0];
        const pageContent = {
            ...normalizedResponse,
            seo: {
                slug,
                ...normalizedResponse?.seo,
            },
        };

        // Fetch and process carousels on the page
        const pageCarousels = await fetchCarouselContent(
            pageContent.blocks
                ?.filter((block: any) =>
                    ['EditorialCarousel', 'EventCarousel'].includes(
                        block.__typename,
                    ),
                )
                ?.map((block: any) => ({
                    title: block.title,
                    id: block.id,
                    size:
                        block.imageOrientation === 'Portrait'
                            ? 'medium'
                            : 'small',
                    showDates: block.showDates,
                })),
        );

        // Sort blocks based on original order
        const order = pageContent?.blocks?.map(
            (block: { sys: { id: string } }) => block?.sys?.id,
        );

        const eventNormalize = normalizeContentfulDeliveryResponse(
            pageContent?.hero?.event,
        );
        const preferredVariantNormalize = normalizeContentfulDeliveryResponse(
            pageContent?.hero?.event?.preferredVariant,
        );

        const hero =
            pageContent?.hero && Object.keys(pageContent.hero).length
                ? { ...pageContent.hero }
                : heroEventFormatted;

        return {
            ...pageContent,
            hero: pageContent?.hero?.__typename
                ? {
                      ...pageContent.hero,
                      event: {
                          ...eventNormalize,
                          preferredVariant: preferredVariantNormalize,
                      },
                  }
                : { ...hero },
            blocks: [...(pageContent?.blocks || []), ...pageCarousels].sort(
                (a, b) => order?.indexOf(a.id) - order?.indexOf(b.id),
            ),
            seo: pageContent.seo,
        };
    } catch (error) {
        console.error('Error fetching flexible page data:', error);
        return null; // Handle the error gracefully or return fallback data
    }
};
