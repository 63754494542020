/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-return */
'use server';

import { AuthError } from '@/reducers/authSlice';
import { transformToOldEvent } from '../data-fetching/fetch/utils';
import { ContentfulEvent } from '@/types/contentful';
import { genericAPIMessages } from '@/messages/genericAPI';

export async function fetchShowsServerSide(
    payload: { auth: { jwt: string } },
    // rejectWithValue: (errorMessage: any) => void
) {
    const {
        auth: { jwt },
    } = payload;
    if (!jwt) throw new AuthError('INVALID_TOKEN', 'Not logged in');
    // When a version is specified, let the /shows endpoint handle the
    // action and return the data in the correct version format.
    const response = await fetch(`${process.env.ONAIR_ENDPOINT}/shows`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json',
            Authorization: 'Bearer ' + jwt,
        },
        cache: 'no-store',
    });

    const { error, data } = await response.json();

    if (!response.ok || error) {
        const errorMessage =
            error ?? genericAPIMessages.error_performing_request;

        return { error: errorMessage };
    }
    const normalizedResponse = data.eventCollection.map(
        (event: ContentfulEvent) => transformToOldEvent(event),
    );

    return normalizedResponse ?? [];
}
