import { format } from 'date-fns';

import type { AuthCustomerInfo } from '../reducers/authSlice';

import { formatDisplayPrice } from './i18n';
import { ContentfulGenre, EventVariant, Event } from '@/types/contentful';
import {
    ActionName,
    AppStoreBannerSources,
    AvailableAppStores,
    BasicEventTraits,
    CartActions,
    CartItem,
    EventDetailsAction,
    EventDetailsTraits,
    IdentifyCallback,
    NativeTrackingChannel,
    OnlyActionTrack,
    PlayerEventAction,
    PurchaseStatus,
    SocialLinkActions,
    TrackActionCallback,
    TrackingChannel,
    TrackingPlatform,
} from '@/types/Segment';
import { CalendarOptions } from '@/types/calendar';

/**
 *
 * @description Helper function used to get show's categories in a single string
 *
 * @param genres - Show's genres
 * @returns A single string with the combined genre.displayLabels of the show
 */
export const getCategoriesFromGenre = (genres?: ContentfulGenre[]): string => {
    if (!Array.isArray(genres)) return '';
    return genres.map(({ displayLabel }) => displayLabel.trim()).join(', ');
};
/**
 *
 * @description A function used by JS and RN apps for the purpose of sending
 * identify logs to segment's server
 *
 * @param identifyCallback - JS's window.analytics.identify or RN's library method
 * @param customer - Authenticated user as stored in token
 */
export const logSegmentIdentify = (
    identifyCallback: IdentifyCallback,
    customer: AuthCustomerInfo,
    channel?: TrackingChannel,
) => {
    const { id, firstName, lastName, email, acceptsMarketing, dateOfBirth } =
        customer;

    // Don't send the request if there is no customer id
    if (!id) return;

    identifyCallback(id, {
        name: `${firstName} ${lastName}`,
        email,
        acceptsMarketing: !!acceptsMarketing,
        channel: channel ?? 'Browser',
        dateOfBirth,
    });
};
/**
 *
 * @returns The title of the show, the title of the selected variant, the starting date and the selected variant
 */
export const getBasicEventTraits = (
    event: Event,
    selectedVariant?: EventVariant,
): BasicEventTraits => {
    const { title, variants, genres, slug, shopifyProductId } = event;

    let [activeVariant] = variants ?? [];

    if (selectedVariant) {
        activeVariant = selectedVariant;
    }

    const showDate =
        activeVariant?.eventStart &&
        format(new Date(activeVariant.eventStart), 'dd MMM yyyy, hh:mm');

    return {
        name: title,
        showVariant: activeVariant?.title,
        showDate,
        activeVariant,
        sku: slug,
        productId: shopifyProductId,
        category: getCategoriesFromGenre(genres),
    };
};
/**
 *
 * @param countryCode - Country's code string eg: NL
 *
 * @returns The same props as getBasicEventTraits method plus the price
 */
export const getEventTraitsWithPrice = (
    event: Event,
    countryCode?: string,
    selectedVariant?: EventVariant,
): EventDetailsTraits => {
    const {
        name,
        showVariant,
        showDate,
        activeVariant,
        sku,
        productId,
        category,
    } = getBasicEventTraits(event, selectedVariant);

    const price =
        countryCode &&
        activeVariant?.price &&
        formatDisplayPrice(countryCode, activeVariant.price);

    return {
        name,
        showVariant,
        showDate,
        price,
        sku,
        productId,
        category,
    };
};
/**
 * Track player events like play, pause, stop
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param playerEventAction - Action we are tracking
 * @param event - Contentful's event
 * @param selectedVariant - Event's variant selected by the user
 * @param duration - Event's duration in seconds
 * @param platform - Platform user is using
 */
export const trackPlayerEvent = ({
    trackCallback,
    playerEventAction,
    event,
    selectedVariant,
    duration,
    platform,
}: {
    trackCallback?: TrackActionCallback;
    playerEventAction: PlayerEventAction;
    event: Event;
    selectedVariant?: EventVariant;
    platform: TrackingPlatform;
    duration?: number;
}) => {
    const { name, showVariant, showDate } = getBasicEventTraits(
        event,
        selectedVariant,
    );

    // Transform seconds to hh:mm format
    const formattedDuration =
        duration && new Date(duration * 1000).toISOString().slice(11, 16);

    const payload = {
        name,
        showVariant,
        showDate,
        platform,
        duration: formattedDuration,
    };
    sessionStorage.setItem(
        `segment-${playerEventAction}-${new Date().getTime()}`,
        JSON.stringify(payload),
    );

    void trackCallback?.(playerEventAction, payload);
};
/**
 * Track CTA button clicks
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param platform - Platform user is using
 * @param eventDetailsAction - Action we are tracking
 * @param event - Contentful's event
 * @param countryCode - Country's code string eg: NL
 * @param selectedVariant - Event's variant selected by the user
 * @param ctaButtonLabel - CTA button's label
 */
export const trackViewEventDetails = ({
    trackCallback,
    platform,
    eventDetailsAction,
    event,
    countryCode,
    selectedVariant,
    ctaButtonLabel,
}: {
    trackCallback?: TrackActionCallback;
    eventDetailsAction: EventDetailsAction;
    platform: TrackingPlatform;
    event: Event;
    countryCode?: string;
    selectedVariant?: EventVariant;
    ctaButtonLabel?: string;
}) => {
    const { name, showVariant, showDate, price, sku, productId } =
        getEventTraitsWithPrice(event, countryCode, selectedVariant);

    const traits: EventDetailsTraits = {
        name,
        showVariant,
        showDate,
        price,
        sku,
        productId,
    };

    if (eventDetailsAction === 'View Event Details') {
        traits.showType = event.type;
    }

    if (eventDetailsAction === 'Hero CTA Clicked') {
        traits.buttonLabel = ctaButtonLabel;
    }
    sessionStorage.setItem(
        `segment-${eventDetailsAction}-${new Date().getTime()}`,
        JSON.stringify({ ...traits, platform }),
    );
    void trackCallback?.(eventDetailsAction, { ...traits, platform });
};
/**
 * Track product tile clicks
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param platform - Platform user is using
 * @param action - Action we are tracking
 * @param event - Contentful's event
 * @param url - User's current url or screen
 */
export const trackProduct = ({
    trackCallback,
    platform,
    action,
    event,
    url,
    purchased,
    available,
    carouselTitle,
}: {
    trackCallback?: TrackActionCallback;
    platform: TrackingPlatform;
    action: ActionName;
    event: Event;
    url: string;
    purchased?: boolean;
    available?: boolean;
    carouselTitle?: string;
}) => {
    const { sku, category, productId, name } = getBasicEventTraits(event);

    const payload = {
        productId,
        platform,
        sku,
        category,
        name,
        url,
        purchased,
        available,
        carouselTitle,
    };

    sessionStorage.setItem(
        `segment-${action}-${new Date().getTime()}`,
        JSON.stringify(payload),
    );

    void trackCallback?.(action, payload);
};
/**
 * Track clicks on app store banners
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param platform - Platform user is using
 * @param store - Apple, Google, Amazon, etc.
 * @param device - Phone, TV etc
 * @param source - footer, contentblock etc.
 * @param url - User's current url or screen
 */
export const trackAppStoreBanners = ({
    trackCallback,
    platform,
    store,
    device,
    source,
    url,
}: {
    trackCallback?: TrackActionCallback;
    store: AvailableAppStores;
    device: NativeTrackingChannel;
    platform: TrackingPlatform;
    source: AppStoreBannerSources;
    url: string;
}) => {
    const payload = {
        platform,
        store,
        device,
        source,
        url,
    };

    sessionStorage.setItem(
        `segment-Appstore Banner Clicked-${new Date().getTime()}`,
        JSON.stringify(payload),
    );
    void trackCallback?.('Appstore Banner Clicked', payload);
};
/**
 * Track clicks on artists or events social media icons or shares of the event
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param trackString - Action we are tracking
 * @param mediumUsed - Social media used to shared or clicked
 * @param url - User's current url or screen
 * @param event - Contentful's event
 * @param countryCode - Country's code string eg: NL
 * @param selectedVariant - Event's variant selected by the user
 * @param platform - Platform user is using
 */
export const trackSocialClickOrShares = ({
    trackCallback,
    trackString,
    mediumUsed,
    url,
    event,
    countryCode,
    selectedVariant,
    platform,
}: {
    trackCallback?: TrackActionCallback;
    trackString: SocialLinkActions;
    mediumUsed: string;
    url: string;
    event: Event;
    countryCode?: string;
    selectedVariant?: EventVariant;
    platform: TrackingPlatform;
}) => {
    const { name, showVariant, sku, category, price, productId } =
        getEventTraitsWithPrice(event, countryCode, selectedVariant);

    void trackCallback?.(trackString, {
        variant: showVariant,
        name,
        mediumUsed,
        productId,
        platform,
        category,
        price,
        sku,
        url,
    });
};
/**
 * Track a simple action with only trait the platform
 *
 * @param action - Action we are tracking
 * @param platform - Platform user is using
 */
export const simpleActionTrack = ({
    trackCallback,
    action,
    platform,
}: {
    trackCallback?: TrackActionCallback;
    action: OnlyActionTrack;
    platform: TrackingPlatform;
}) => {
    void trackCallback?.(action, { platform });
};
/**
 * Web only method used to track the purchase flow
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param purchaseStatus - Action we are tracking
 * @param platform - Platform user is using
 * @param url - User's current url or screen
 * @param cartItems - Items in users cart
 * @param cartId - User's cart id
 * @param currency - User's currency used for the purchase
 */
export const webPurchaseTrack = ({
    trackCallback,
    purchaseStatus,
    platform,
    url,
    cartItems,
    cartId,
    currency,
}: {
    trackCallback?: TrackActionCallback;
    purchaseStatus: PurchaseStatus;
    platform: TrackingPlatform;
    url: string;
    cartItems?: CartItem[];
    cartId?: string;
    currency?: string;
}) => {
    const products = cartItems?.map((cartItem) => {
        return {
            name: cartItem.productTitle,
            variant: cartItem.variantTitle,
            price: cartItem.price,
            quantity: cartItem.quantity,
            productId: cartItem.shopifyProductId,
        };
    });

    const payload = {
        cartId,
        products,
        currency,
        platform,
        url,
    };

    sessionStorage.setItem(
        `segment-${purchaseStatus}-${new Date().getTime()}`,
        JSON.stringify(payload),
    );

    void trackCallback?.(purchaseStatus, payload);
};
/**
 * Native only method used to track the purchase flow
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param event - Contentful's event
 * @param platform - Platform user is using
 * @param purchaseStatus - Action we are tracking
 * @param countryCode - Country's code string eg: NL
 * @param selectedVariant - Event's variant selected by the user
 * @param url - User's current url or screen
 */
export const nativePurchaseTrack = ({
    trackCallback,
    event,
    platform,
    purchaseStatus,
    countryCode,
    selectedVariant,
    url,
}: {
    trackCallback?: TrackActionCallback;
    event: Event;
    platform: TrackingPlatform;
    purchaseStatus: PurchaseStatus;
    url: string;
    countryCode?: string;
    selectedVariant?: EventVariant;
}) => {
    const {
        name,
        showVariant: variant,
        price,
        sku,
        category,
        productId,
    } = getEventTraitsWithPrice(event, countryCode, selectedVariant);
    // in iap quantity will always be 1
    void trackCallback?.(purchaseStatus, {
        productId,
        variant,
        name,
        price,
        sku,
        category,
        quantity: 1,
        platform,
        url,
    });
};
/**
 * Track the items added or removed in user's cart
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param cartAction - Action we are tracking
 * @param event - Contentful's event
 * @param cartId - User's cart id
 * @param url - User's current url or screen
 * @param selectedVariant - Event's variant selected by the user
 * @param platform - Platform user is using
 * @param countryCode - Country's code string eg: NL
 * @param quantity - Quantity of the items, in case of add will always be 1
 */
export const trackCartAddOrRemove = ({
    trackCallback,
    cartAction,
    event,
    cartId,
    url,
    selectedVariant,
    platform,
    countryCode,
    quantity = 1,
}: {
    trackCallback?: TrackActionCallback;
    cartAction: CartActions;
    event: Event;
    cartId: string;
    url: string;
    selectedVariant?: EventVariant;
    quantity?: number;
    platform: TrackingPlatform;
    countryCode?: string;
}) => {
    const { name, showVariant, category, price, sku, productId } =
        getEventTraitsWithPrice(event, countryCode, selectedVariant);

    const payload = {
        platform,
        cartId,
        productId,
        sku,
        category,
        name,
        variant: showVariant,
        price,
        quantity,
        url,
    };

    sessionStorage.setItem(
        `segment-${cartAction}-${new Date().getTime()}`,
        JSON.stringify(payload),
    );

    void trackCallback?.(cartAction, payload);
};
/**
 * Track events saved to user's calendar
 *
 * @param trackCallback - JS's window.analytics.track or RN's library method
 * @param event - Contentful's event
 * @param calendarType - Type of user's calendar eg: Google
 * @param platform - Platform user is using
 */
export const trackAddToCalendar = ({
    trackCallback,
    event,
    calendarType,
    platform,
}: {
    trackCallback?: TrackActionCallback;
    event: Event;
    platform: TrackingPlatform;
    calendarType: CalendarOptions;
}) => {
    const { name, sku } = getBasicEventTraits(event);
    void trackCallback?.('Add Event To Calendar', {
        name,
        sku,
        calendarType,
        platform,
    });
};
