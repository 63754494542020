import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCustomerErrorMessage } from '../errors/utils';
import {
    customerRecoverMutation,
    customerResetByUrlMutation,
    generateCustomerRecoverVariables,
    generateCustomerResetByUrlVariables,
} from '../queries/passwordQueries';

import {
    ShopifyCustomerOnlyErrorsResponse,
    ShopifyCustomerResponse,
} from '@/types/Shopify';
import { customerMessages } from '@/utils/messages/customer';
import fetchShopify from '@/utils/fetchShopify';

export enum ForgotPasswordActionTypes {
    sendResetPasswordEmail = 'forgotPassword/sendResetPasswordEmail',
    changePassword = 'forgotPassword/changePassword',
}

export const sendResetPasswordEmailAction = createAsyncThunk(
    ForgotPasswordActionTypes.sendResetPasswordEmail,
    async (email: string, { rejectWithValue }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { errors, data }: ShopifyCustomerOnlyErrorsResponse =
            await fetchShopify({
                query: customerRecoverMutation,
                queryVariables: generateCustomerRecoverVariables(email),
            });
        if (
            errors?.length ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            data?.customerRecover?.customerUserErrors?.length > 0
        ) {
            const errorMessage = getCustomerErrorMessage({
                defaultErrorMessage:
                    customerMessages.error_sending_recovery_email,
                errors,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                errorCode: data?.customerRecover?.customerUserErrors?.[0].code,
            });
            return rejectWithValue(errorMessage);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data;
    },
);

export const changePasswordWithResetTokenAction = createAsyncThunk(
    ForgotPasswordActionTypes.changePassword,
    async (
        payload: {
            userId: string;
            resetToken: string;
            password: string;
        },
        { rejectWithValue },
    ) => {
        const { userId, resetToken, password } = payload;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { errors, data }: ShopifyCustomerResponse = await fetchShopify({
            query: customerResetByUrlMutation,
            queryVariables: await generateCustomerResetByUrlVariables({
                userId,
                resetToken,
                password,
            }),
        });

        if (
            errors?.length ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            data?.customerResetByUrl?.customerUserErrors?.length > 0
        ) {
            const errorMessage = getCustomerErrorMessage({
                defaultErrorMessage: customerMessages.error_resetting_password,
                errors,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                errorCode:
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    data?.customerResetByUrl?.customerUserErrors?.[0].code,
            });
            return rejectWithValue(errorMessage);
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data;
    },
);
