import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as EmailValidator from 'email-validator';

import {
    changeEmailAction,
    changeProfileAction,
} from '../actions/profileActions';
import fetch from '@/utils/fetch';
import { VALIDATION_ERROR_MESSAGES } from '../errors';

import { getEnvironmentVariableServerSide } from '../../../next/src/utils/server-functions/getEnvServerSide';

import type { SerializedError, PayloadAction } from '@reduxjs/toolkit';
import { multiLoginToken } from '@/utils/auth';
import { parseIdFromShopify } from '@/utils/shopifyParser';
import { genericAPIMessages } from '@/messages/genericAPI';

export type AuthErrorCode =
    | 'INVALID_EMAIL'
    | 'INVALID_PASSWORD'
    | 'INVALID_CREDENTIALS'
    | 'INVALID_TOKEN'
    | 'UNEXPECTED'
    | 'INVALID_FIRSTNAME'
    | 'INVALID_LASTNAME'
    | 'INVALID_DATE_OF_BIRTH'
    | 'INVALID_TERMS_AND_CONDITIONS'
    | 'ERROR_RESPONSE';

export class AuthError extends Error implements SerializedError {
    readonly code: AuthErrorCode;

    constructor(code: AuthErrorCode, message: string) {
        super(message);
        this.code = code;
        this.name = 'AuthError';
    }
}
export interface MultiLoginResponse {
    data?: MultiLoginToken;
    error?: string;
}
export interface MultiLoginToken {
    acknowledgeTimestamp: number;
    jwt: string;
    handoverId: string;
    userId: string;
    timestamp: number;
    customer: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    };
    expiresAt: string;
    customerAccessToken: string;
}
export interface AuthCustomerInfo {
    email: string;
    firstName: string;
    lastName: string;
    id?: string;
    acceptsMarketing?: boolean;
    dateOfBirth?: string;
}

export interface AuthToken {
    jwt: string;
    customerAccessToken: string;
    expiresAt: string; // eg "2021-11-24T16:25:51Z"
    customerInfo?: AuthCustomerInfo;
}

export interface AuthState {
    loginStatus:
        | 'UNINITIALIZED'
        | 'LOGGED_OUT'
        | 'LOGGED_IN'
        | 'IN_PROGRESS'
        | 'FAILED'
        | 'TOKEN_EXPIRED';
    registerStatus:
        | 'UNINITIALIZED'
        | 'IN_PROGRESS'
        | 'FAILED'
        | 'REGISTER_COMPLETE';
    registerError?: AuthError;
    loginError?: AuthError;
    token?: AuthToken;
    expiredTokenEmail?: string;
}

export const authSliceInitialState: AuthState = {
    loginStatus: 'UNINITIALIZED',
    registerStatus: 'UNINITIALIZED',
};

interface AuthResponse {
    data?: AuthToken;
    error?: string;
}

export enum AuthActionTypes {
    loginWithEmailAndPassword = 'auth/loginWithEmailAndPassword',
    multiLogin = 'auth/multiLogin',
    register = 'auth/register',
}

export const multiLogin = createAsyncThunk(
    AuthActionTypes.multiLogin,
    async (argument: { handoverId?: string }) => {
        const { handoverId } = argument;

        try {
            const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
                'ONAIR_ENDPOINT',
            ]);
            const response = await fetch(
                `${ONAIR_ENDPOINT!}/login-handover/poll`,
                {
                    method: 'POST',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify({
                        handoverId,
                    }),
                },
            );
            const { error, data } =
                (await response.json()) as MultiLoginResponse;

            if (!response.ok || error || !data?.jwt) {
                const errorMessage =
                    error || genericAPIMessages.error_performing_request;
                throw new AuthError('UNEXPECTED', errorMessage);
            }

            return data;
        } catch (error: any) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.name === 'AuthError') throw error;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            throw new AuthError('UNEXPECTED', error.message);
        }
    },
);
// silentRelogin boolean, only use in edge-cases, it doesn't change the loginStatus to PENDING so that users do not get re-routed
// from private parts of the app back to the login for a millisecond. This way we can re-log on a users behalf without him noticing
export const loginWithEmailAndPassword = createAsyncThunk(
    AuthActionTypes.loginWithEmailAndPassword,
    async (argument: {
        email: string;
        password: string;
        silentRelogin?: boolean;
    }) => {
        const { email, password } = argument;

        if (!email || !EmailValidator.validate(email)) {
            throw new AuthError(
                'INVALID_EMAIL',
                VALIDATION_ERROR_MESSAGES.INVALID_EMAIL,
            );
        }
        if (!password)
            throw new AuthError(
                'INVALID_PASSWORD',
                VALIDATION_ERROR_MESSAGES.PASSWORD_EMPTY,
            );

        try {
            const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
                'ONAIR_ENDPOINT',
            ]);
            const response = await fetch(`${ONAIR_ENDPOINT!}/login`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });
            const { error, data } = (await response.json()) as AuthResponse;

            if (!response.ok || error) {
                const errorMessage =
                    error || genericAPIMessages.error_performing_request;
                throw new AuthError('UNEXPECTED', errorMessage);
            }
            return data!;
        } catch (error: any) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.name === 'AuthError') throw error;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            throw new AuthError('UNEXPECTED', error.message);
        }
    },
);

export const register = createAsyncThunk(
    AuthActionTypes.register,
    async (argument: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        dateOfBirth: string;
        acceptsMarketing: boolean;
        acceptsTermsAndConditions: boolean;
        reCaptchaToken: string;
    }) => {
        const {
            email,
            password,
            firstName,
            lastName,
            dateOfBirth,
            acceptsMarketing,
            acceptsTermsAndConditions,
            reCaptchaToken,
        } = argument;

        if (!firstName || firstName.length > 255) {
            throw new AuthError(
                'INVALID_FIRSTNAME',
                'Your first name can not be longer than 255 characters',
            );
        }

        if (!lastName || lastName.length > 255) {
            throw new AuthError(
                'INVALID_LASTNAME',
                'Your last name can not be longer than 255 characters',
            );
        }

        if (!dateOfBirth) {
            throw new AuthError(
                'INVALID_DATE_OF_BIRTH',
                'This birthdate does not seem to be valid',
            );
        }

        if (!email || !EmailValidator.validate(email)) {
            throw new AuthError(
                'INVALID_EMAIL',
                VALIDATION_ERROR_MESSAGES.INVALID_EMAIL,
            );
        }
        if (!password || password.length < 5)
            // We need to add password validation policy here
            throw new AuthError(
                'INVALID_PASSWORD',
                'Please type your password, has to be 5 characters minimum',
            );

        if (!acceptsTermsAndConditions) {
            throw new AuthError(
                'INVALID_TERMS_AND_CONDITIONS',
                'You have to accept our terms and conditions',
            );
        }

        const { ONAIR_ENDPOINT } = await getEnvironmentVariableServerSide([
            'ONAIR_ENDPOINT',
        ]);

        try {
            const response = await fetch(`${ONAIR_ENDPOINT!}/register`, {
                method: 'POST',
                headers: { 'content-type': 'application/json' },
                body: JSON.stringify({
                    email,
                    password,
                    firstName,
                    lastName,
                    dateOfBirth,
                    acceptsMarketing,
                    acceptsTermsAndConditions,
                    reCaptchaToken,
                }),
            });

            const { error, data } = (await response.json()) as AuthResponse;

            if (!response.ok || error) {
                const errorMessage =
                    error || genericAPIMessages.error_performing_request;
                throw new AuthError('UNEXPECTED', errorMessage);
            }

            return data!;
        } catch (error: any) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error.name === 'AuthError') throw error;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            throw new AuthError('UNEXPECTED', error.message);
        }
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: authSliceInitialState,
    reducers: {
        logout(state) {
            state.loginStatus = 'LOGGED_OUT';
            state.registerStatus = 'UNINITIALIZED';
            state.loginError = undefined;

            // Setting token to undefined, we listen to if undefined in the web to remove localstorage session
            state.token = undefined;
        },
        tokenExpired(state, action: PayloadAction<string | undefined>) {
            state.loginStatus = 'TOKEN_EXPIRED';
            state.loginError = undefined;

            // Saves the user's email to be prefilled.
            state.expiredTokenEmail = action.payload;
            state.token = undefined;
        },
        loginWithToken(state, action: PayloadAction<AuthToken>) {
            try {
                state.token = action.payload;
                state.loginStatus = 'LOGGED_IN';
                state.loginError = undefined;
            } catch (error) {
                state.loginStatus = 'FAILED';
                state.loginError = error as AuthError;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(changeEmailAction.fulfilled, (state, action) => {
            if (state.token?.customerInfo) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                state.token.customerInfo = {
                    ...state.token.customerInfo,
                    ...action.payload,
                    // parse shopify id
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                    id: parseIdFromShopify(action.payload.id),
                };
            }
        });
        builder.addCase(changeProfileAction.fulfilled, (state, action) => {
            if (state.token?.customerInfo) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                state.token.customerInfo = {
                    ...state.token.customerInfo,
                    ...action.payload,
                    // parse shopify id
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                    id: parseIdFromShopify(action.payload.id),
                };
            }
        });
        builder.addCase(loginWithEmailAndPassword.pending, (state, action) => {
            // If this is a relog, don't change loginStatus to IN_PROGRESS cause it will automatically kick users out of website
            if (!action.meta.arg.silentRelogin) {
                state.loginStatus = 'IN_PROGRESS';
                state.loginError = undefined;
            }
        });
        builder.addCase(
            loginWithEmailAndPassword.fulfilled,
            (state, action) => {
                state.loginStatus = 'LOGGED_IN';
                state.token = action.payload;
            },
        );
        builder.addCase(loginWithEmailAndPassword.rejected, (state, action) => {
            state.loginStatus = 'FAILED';
            state.loginError = action.error as AuthError;
        });
        builder.addCase(multiLogin.pending, (state, action) => {
            state.registerStatus = 'IN_PROGRESS';
            state.registerError = undefined;
        });
        builder.addCase(multiLogin.fulfilled, (state, action) => {
            state.loginStatus = 'LOGGED_IN';
            state.token = multiLoginToken(action.payload);
        });
        builder.addCase(multiLogin.rejected, (state, action) => {
            state.loginStatus = 'FAILED';
            state.loginError = action.error as AuthError;
        });
        builder.addCase(register.pending, (state) => {
            state.registerStatus = 'IN_PROGRESS';
            state.registerError = undefined;
        });
        builder.addCase(register.fulfilled, (state) => {
            state.registerStatus = 'REGISTER_COMPLETE';
            state.registerError = undefined;
        });
        builder.addCase(register.rejected, (state, action) => {
            state.registerStatus = 'FAILED';
            state.registerError = action.error as AuthError;
        });
    },
});

export const authActions = authSlice.actions;
