import { createSlice } from '@reduxjs/toolkit';

import { NotificationType } from '../enums/Notification';
import { profileMessages } from '../messages/profile';
import { changeEmailAction } from '../actions/profileActions';

import { buildNotification } from '@/utils/notification';
import { NotificationObject } from '@/types/Notification';

export type ChangeEmailStatus =
    | 'CHANGE_EMAIL_PENDING'
    | 'CHANGE_EMAIL_SUCCESSFUL'
    | 'CHANGE_EMAIL_FAILURE';

export interface ChangeEmailState {
    notification?: NotificationObject;
    status?: ChangeEmailStatus;
}

export const changeEmailSliceInitialState: ChangeEmailState = {};

export const changeEmailSlice = createSlice({
    name: 'changeEmail',
    initialState: changeEmailSliceInitialState,
    reducers: {
        resetState: (state) => {
            state.notification = undefined;
            state.status = undefined;
        },
        updateNotification: (state, { payload }) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.notification = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(changeEmailAction.fulfilled, (state) => {
                state.status = 'CHANGE_EMAIL_SUCCESSFUL';
                state.notification = buildNotification(
                    profileMessages.success_updating_profile,
                    NotificationType.success,
                );
            })
            .addCase(changeEmailAction.rejected, (state, { payload }) => {
                state.status = 'CHANGE_EMAIL_FAILURE';
                state.notification = buildNotification(
                    String(payload),
                    NotificationType.error,
                );
            })
            .addCase(changeEmailAction.pending, (state) => {
                state.status = 'CHANGE_EMAIL_PENDING';
                state.notification = undefined;
            });
    },
});

export const changeEmailActions = changeEmailSlice.actions;
