
import { DEFAULT_COUNTRY_CODE } from "@/types/contentful";
import type { AttributeInput } from "../../types/shopifyGenerics";

// It's suffixed with 'Input', because this type is used as a GraphQL input variable
export type CartLineInput = {
	merchandiseId: string;
	quantity: number;
	shopifyVariantId?: string;
	sellingPlanId?: string;
	attributes?: AttributeInput[];
};

export type CartLineUpdateInput = {
	id: string;
	quantity: number;
};

export type CreateCartQueryArgs = {
	lines: CartLineInput[];
	attributes?: AttributeInput[];
	customerAccessToken?: string;
	email?: string;
	discountCodes?: string[];
	countryCode?: string;
};

export type CartInput = {
	cartInput: {
		attributes?: AttributeInput[];
		buyerIdentity?: {
			countryCode?: string;
			customerAccessToken?: string;
			email?: string;
		};
		discountCodes?: string[];
		lines: CartLineInput[];
	};
	countryCode?: string;
};

export const generateCreateCartVariables = ({
	attributes,
	email,
	discountCodes,
	lines,
	countryCode,
}: CreateCartQueryArgs): CartInput => ({
	cartInput: {
		attributes,
		buyerIdentity: {
			countryCode: countryCode || DEFAULT_COUNTRY_CODE,
			// customerAccessToken, TODO: sending customerAccessToken causes problem on Shopify checkout page, will be handled in the future
			email,
		},
		discountCodes,
		lines,
	},
	countryCode: countryCode || DEFAULT_COUNTRY_CODE,
});

export const CART_FRAGMENT = `
    fragment CartFields on Cart {
        id
        createdAt
        updatedAt
        attributes { 
            key
            value
        }
        lines(first: 100) {
            edges {
                node {
                    id
                    quantity
                    attributes { 
                        key
                        value
                    }   
                    estimatedCost{
                        totalAmount  {
                            amount
                            currencyCode
                        }
                        subtotalAmount {
                            amount
                            currencyCode  
                        }
                    },
                    merchandise {
                        ... on ProductVariant {
                            id
                            title
                            sku
                            priceV2 {
                                currencyCode
                                amount
                            }
                            compareAtPriceV2 {
                                currencyCode
                                amount
                            }
                            product {
                                id
                            }
                        }
                    }
                }
            }
        }
        estimatedCost {
            totalAmount {
                amount
                currencyCode
            }
            subtotalAmount {
                amount
                currencyCode
            }
            totalTaxAmount {
                amount
                currencyCode
            }
            totalDutyAmount {
                amount
                currencyCode
            }
        }
        checkoutUrl
    }
`;

export const createCartMutation = `
    ${CART_FRAGMENT}
    mutation createCart($cartInput: CartInput, $countryCode: CountryCode) @inContext(country: $countryCode){
        cartCreate(input: $cartInput) {
            cart {
                ...CartFields
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export type AddCartLinesInput = {
	cartId: string;
	lines: CartLineInput[];
	shopifyVariantId?: string;
	countryCode?: string;
};

export const addCartLineMutation = `
    ${CART_FRAGMENT}
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!, $countryCode: CountryCode) @inContext(country: $countryCode){
        cartLinesAdd(cartId: $cartId, lines: $lines) {
            cart {
                ...CartFields
            }
            userErrors {
                field
                message
            }
        }
    }
`;

export type UpdateCartLinesInput = {
	cartId: string;
	lines: CartLineUpdateInput[];
	shopifyVariantId?: string;
	countryCode?: string;
};

export type FetchCartLinesInput = {
	cartId: string;
	countryCode: string; // change as CountryCode
};

export const updateCartLineMutation = `
    ${CART_FRAGMENT}
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!, $countryCode: CountryCode) @inContext(country: $countryCode){
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
            cart {
                ...CartFields
            }
            userErrors {
                field
                message
            }
        }
    }
`;
export const fetchCartQuery = `
    ${CART_FRAGMENT}
    query cart($cartId: ID!, $countryCode: CountryCode) @inContext(country: $countryCode){
        cart(id: $cartId) {
            ...CartFields
        }
    }
`;

export type CheckoutMutationResponse = {
	checkoutCreate: {
		checkout: {
			id: string;
			webUrl: string;
			lineItems: {
				edges: [
					{
						node: {
							title: string;
							quantity: number;
						};
					}
				];
			};
		};
	};
};

export const checkoutMutation = `mutation checkoutCreate($input: CheckoutCreateInput!, $countryCode: CountryCode) @inContext(country: $countryCode) {
    checkoutCreate(input: $input) { 
      checkout {
         id
         webUrl
         lineItems(first: 20) {
           edges {
             node {
               title
               quantity
             }
           }
         }
      }
    }
  }`;

export type CheckoutVariablesType = {
	input: {
		lineItems: Array<{ variantId: string; quantity: number }>;
	};
	countryCode: string;
};

export const cartLinesRemoveMutation = `
    ${CART_FRAGMENT}
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!, $countryCode: CountryCode) @inContext(country: $countryCode) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
            ...CartFields
        }
        userErrors {
            field
            message
        }
        }
  }`;
